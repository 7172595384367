/* eslint-disable */
import React, { useState, useRef } from "react";
import "./styles.scss";
import Slider from "react-slick";
import projectData from "../../config/projectData";

const ProjectSlider = ({ selProject, setSelProject }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const slider123 = useRef();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };
  return (
    <div className="project-slider-container">
      {window.innerWidth > 900 ? (
        <div className="text-info-container">
          <h1
            style={{ width: "90%", whiteSpace: "nowrap", paddingBottom: "0px", textTransform: "none" }}
          >
            Products we are proud of
          </h1>
          <div className="arrows-container">
            <div className="arrows">
              <div
                className={`left-arrow ${activeSlide === 0 && "inactive"}`}
                id="left"
                onClick={() => {
                  slider123.current.slickPrev();
                }}
              />
              <div
                className={`right-arrow ${
                  activeSlide + 3 === projectData.length && "inactive"
                }`}
                id="right"
                onClick={() => {
                  slider123.current.slickNext();
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="top-container">
          <h1>Products we are proud of</h1>
        </div>
      )}

      {window.innerWidth < 900 ? (
        <>
          {projectData.map((project, j) => {
            return (
              <>
                <div className="mobile-project" key={j}>
                  <div
                    className="project-logo"
                    style={{
                      backgroundImage: `url(${project.url})`,
                    }}
                  />
                  <h2 style={{ fontSize: "16px" }}>
                    <b>{project.text}</b>
                  </h2>
                  <div
                    className="project-image"
                    style={{
                      backgroundImage: `url(${project?.mobileImage})`,
                      height:
                        project.selected === "enrpi"
                          ? "101vh"
                          : project.selected === "wink" && "101vh",
                    }}
                  />
                </div>
                {j < projectData.length - 1 && <div className="divider" />}
              </>
            );
          })}
        </>
      ) : (
        <Slider ref={slider123} style={{ marginLeft: "-4%" }} {...settings}>
          {projectData.map((project, j) => {
            return (
              <div
                className={`project ${
                  project.text === selProject.text && project.selected
                }`}
                key={j}
                id={`project${j}`}
                onClick={() => {
                  setSelProject(project);
                }}
              >
                <div
                  className="project-image"
                  style={{
                    backgroundImage: `url(${project.url})`,
                  }}
                />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};

export default ProjectSlider;
