/* eslint-disable */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setCurrentStatus, setHeaderStatus } from "../../actions";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";

function MobileBlog({ setHeaderStatus, setCurrentStatus }) {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      setHeaderStatus(null);
    }
  }, [location.pathname, setHeaderStatus]);

  return (
    <>
      <div className="divider" style={{ width: "87.5%" }} />
      <div className="mobile-footer" id={"footer"}>
        <div className="mobile-header-logo" />
        <div
          className="mobile-footer-element"
          onClick={() => {
            setCurrentStatus("home");
            history.push("/");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          Home
        </div>
        <div
          className="mobile-footer-element"
          onClick={() => window.open(Presentation)}
        >
          Portfolio
        </div>
        <div
          className="mobile-footer-element"
          onClick={() => {
            setCurrentStatus("form");
            history.push("/");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          Contact us
        </div>
        <div
          className="mobile-footer-element border"
          onClick={() => {
            history.push("/news");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          News
        </div>
        <div className="mobile-flex-container">
          <div className="email-icon" />
          <p style={{ width: "90%" }}>team@fidweb.net</p>
        </div>
        <div className="mobile-flex-container">
          <div className="location-icon" />
          <p style={{ width: "90%" }}>
            Ivan Abadjiev Street, 2 office building 4th floor Sofia, Bulgaria
          </p>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHeaderStatus: (payload) => dispatch(setHeaderStatus(payload)),
  setCurrentStatus: (payload) => dispatch(setCurrentStatus(payload)),
});

export default connect(null, mapDispatchToProps)(MobileBlog);
