import amarant from "../assets/amarant-logo.svg";
import alpi from "../assets/alpi-logo.svg";
import digidrive from "../assets/digi-drive-logo.svg";
import enrpi from "../assets/enrpi-logo.svg";
import fightscout from "../assets/fightscout-logo.svg";
import immotech from "../assets/immotech-logo.svg";
import socourt from "../assets/socourt-logo.svg";
import wink from "../assets/wink-logo.svg";
// import geowash from "../assets/geowash-logo.svg";
import geowash from "../assets/Mobi-Wash.svg";
import ppa from "../assets/ppa-logo.svg";
import amarantDesktop from "../assets/amarant-desktop-screens.png";
import alpiDesktop from "../assets/alpi-desktop-screens.png";
import digidriveDesktop from "../assets/digidrive-desktop-screens.png";
import enrpiDesktop from "../assets/enrpi-desktop-screens.png";
import fightScoutDesktop from "../assets/fightscout-desktop-screens.png";
import immotechDesktop from "../assets/immotech-desktop-screens.png";
import socourtDesktop from "../assets/socourt-desktop-screens.png";
import winkDesktop from "../assets/wink-desktop-screens.png";
import geowashDesktop from "../assets/mobiwash-desktop-screens.png";
import ppaDesktop from "../assets/ppa-desktop-screens.png";
import amarantMobile from "../assets/mobile/amarant-mobile-screens.png";
import alpimobile from "../assets/mobile/alpi-mobile-screens.png";
import digidrivemobile from "../assets/mobile/digidrive-mobile-screens.png";
import enrpimobile from "../assets/mobile/enrpi-mobile-screens.png";
import fightScoutmobile from "../assets/mobile/fightscout-mobile-screens.png";
import immotechmobile from "../assets/mobile/immotech-mobile-screens.png";
import socourtmobile from "../assets/mobile/socourt-mobile-screens.png";
import winkmobile from "../assets/mobile/wink-mobile-screens.png";
import geowashmobile from "../assets/mobile/geowash-mobile-screens.png";
import ppamobile from "../assets/mobile/ppa-mobile-screens.png";
const projects = [
  {
    text: "The best Bulgarian mobile app, awarded by Apple & Google",
    url: amarant,
    imageUrl: amarantDesktop,
    apple: "https://apps.apple.com/us/app/amarant-bulgaria/id1506724182",
    play: "https://play.google.com/store/apps/details?id=com.amarantapp&hl&pli=1",
    site: "https://www.amarantbg.com/",
    selected: "amarant",
    mobileImage:amarantMobile
  },
  {
    text: "Online Shop with 25k+ transactions every month",
    url: alpi,
    imageUrl: alpiDesktop,
    site: "https://www.alpi-nature.com/",
    selected: "alpi",
    mobileImage:alpimobile
  },
  {
    text: "Revolutionary solution for young drivers",
    url: digidrive,
    imageUrl: digidriveDesktop,
    apple: "https://apps.apple.com/bg/app/digidrive/id1482770290",
    play: "https://play.google.com/store/apps/details?id=com.socourt.digidrive",
    site: "https://www.digi-drive.app/ ",
    selected: "digidrive",
    mobileImage:digidrivemobile
  },
  {
    text: "The energy market is a complex topic - we make it easy",
    url: enrpi,
    imageUrl: enrpiDesktop,
    email: "test@abv.bg",
    selected: "enrpi",
    mobileImage:enrpimobile
  },
  {
    text: "Just like LinkedIn but for the combat world - with active users from 50+ countries",
    url: fightscout,
    imageUrl: fightScoutDesktop,
    apple:
      "https://apps.apple.com/bg/app/fightscout/id1590863097?fbclid=IwAR13u_6XIomgZqkcJJuSPiXTrcSVrW94ALkVS2MOMTGHJl9ohpIIVpsKQ2U",
    play: "https://play.google.com/store/apps/details?id=com.socourt.combater",
    site: "https://www.fightscout.app/",
    selected: "fightscout",
    mobileImage:fightScoutmobile
  },
  {
    text: "All your household in one single app - bills, services, finances, neighbors, and many more",
    url: immotech,
    imageUrl: immotechDesktop,
    apple: "https://apps.apple.com/bg/app/immotech/id1467332873",
    play: "https://play.google.com/store/apps/details?id=com.socourt.immotech",
    site: "https://www.immotech.bg/",
    selected: "immotech",
    mobileImage:immotechmobile
  },
  {
    text: "Cutting-edge technology for capturing only the best moments in sports",
    url: socourt,
    imageUrl: socourtDesktop,
    site: "https://thesocourt.com/",
    apple: "https://apps.apple.com/us/app/socourt/id1039455431",
    selected: "socourt",
    mobileImage:socourtmobile
  },
  {
    text: "All-in-one solution for EV charging",
    url: wink,
    imageUrl: winkDesktop,
    apple: "https://apps.apple.com/app/wink-charging/id1615549467",
    play: "https://play.google.com/store/apps/details?id=com.winkchargingapp&hl=bg&gl=US",
    site: "https://www.winkcharging.com/",
    selected: "wink",
    mobileImage:winkmobile
  },
  {
    text: "Revolution in car washing brought to users thanks to Fidweb",
    url: geowash,
    imageUrl: geowashDesktop,
    apple: "https://apps.apple.com/lt/app/mobiwash-app/id6449843008",
    play: "https://play.google.com/store/apps/details?id=com.socourt.mobiwash",
    selected: "geowash",
    mobileImage:geowashmobile
  },
  {
    text: "Modern Telemedicine product for the Malaysian market",
    url: ppa,
    imageUrl: ppaDesktop,
    site: "https://www.ppa-wound.app/",
    apple:
      " https://apps.apple.com/bg/app/professional-patients-app/id1570858022",
    play: "https://play.google.com/store/apps/details?id=com.socourt.wpa",
    selected: "ppa",
    mobileImage:ppamobile
  },
];

export default projects;
