import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { setHeaderStatus } from "../../actions";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { setSelectedArticle } from "../../actions";
import "./styles.scss";
import Slider from "react-slick";
import amarantNews from "../../assets/amarant-news-2.png";
import FSmin from "../../assets/1-min.png";
import immotechNews from "../../assets/immotech.png";
import HolidaysNews from "../../assets/mobile/2022-mobile.png";
import NewsText from "../../config/NewsText";
import fightScoutNew from "../../assets/fightscout-web.png";
import gfl from "../../assets/gfl-web.png";
import proakt from "../../assets/proakt-web.png";
import fightScoutCombine from "../../assets/fightscout-web-new.png";
import HolidayPicture from "../../assets/3-march-web.png";
import fantastico from "../../assets/fidweb-fantastico_optimized.png";
import fightScoutCommunication from "../../assets/fidweb-article-fight-scout-updates_optimized.png";
import fightScoutWebsite from "../../assets/fidweb-article-fight-scout-new-website.png";
import fightScoutBKFC from "../../assets/fightscout_bkfc.png";
import wink from "../../assets/fidweb-wink-updates_optimized.png";
import testMyStress from "../../assets/fidweb-practicle-stress_optimized.png";
import testMyStressNew from "../../assets/fidweb-testmystress-coming-soon.png";
import mobiWash from "../../assets/fidweb-mobiwash_optimized.png";
import immotechGermany from "../../assets/fidweb-immotech-germany_optimized.png";
import enrpi from "../../assets/fidweb-enrpi-new-client_optimized.png";
import winkNews from "../../assets/fidweb-wink-articles_optimized.png";
import alpi from "../../assets/fidweb-alpi-article_optimized.png";
import amarant3 from "../../assets/amarant-3.png";
import scoutify from "../../assets/Scoutify-App-Article-Image.png";

const articles = [
  {
    title: "Scoutify App: Revolutionising Athlete Talent Tracking",
    imageUrl: scoutify,
    active: true,
  },
  {
    title: "Amarant App Achieves Stellar Rating on App Store and Google Play Store!",
    imageUrl: amarant3,
    active: true,
  },
  {
    title: "Fight Scout Named Official App for Fighter Recruitment for BKFC",
    imageUrl: fightScoutBKFC,
    active: true,
  },
  {
    title: "FIGHT SCOUT presents its new website",
    imageUrl: fightScoutWebsite,
    active: true,
  },
  {
    title: "Unveiling the Partnership with the Fantastico group: Empowering Growth and Knowledge",
    imageUrl: fantastico,
    active: true,
  },
  {
    title: "Elevating Communication in the Digital Age: The All-New FightScout Mobile App",
    imageUrl: fightScoutCommunication,
    active: true,
  },
  {
    title: "Wink Mobile App’s Game-Changing Payment Update",
    imageUrl: wink,
    active: true,
  },
  {
    title: "Introducing Practical Stress: Evolutionizing Psychological Consultations...",
    imageUrl: testMyStress,
    active: true,
  },
  {
    title: "MobiWash: Revolutionizing Car Washing with Convenience at Your Fingertips",
    imageUrl: mobiWash,
    active: true,
  },
  {
    title: "Immotech App’s Major Partnership Set to Redefine the German Tech Scene",
    imageUrl: immotechGermany,
    active: true,
  },
  {
    title: "Empowering Efficiency: EnRPI Welcomes V&K Energy as New Client",
    imageUrl: enrpi,
    active: true,
  },
  {
    title: "Wink Mobile App: Earning Recognition and Making Waves in Forbes and Kapital",
    imageUrl: winkNews,
    active: true,
  },
  {
    title: "ALPI's Remarkable Journey: Featured in Forbes Magazine",
    imageUrl: alpi,
    active: true,
  },
  {
    title: "New project Test My Stress",
    imageUrl: testMyStressNew,
    active: true,
  },
  {
    title: "Immotech launching soon in Germany",
    imageUrl: immotechGermany,
    active: true,
  },
  {
    title: "Честит Национален празник, българи!",
    imageUrl: HolidayPicture,
    active: true,
  },
  {
    title: "New Partnership Announced with Social Media Platform FightScout",
    imageUrl: fightScoutCombine,
    active: true,
  },
  {
    title: "EnRPI welcomes Proakt",
    imageUrl: proakt,
    active: true,
  },
  {
    title: "New Year, new amazing project completed!",
    imageUrl: gfl,
    active: true,
  },
  {
    title: "Fight Scout raises 50 000$ pre-seed funding!",
    imageUrl: fightScoutNew,
    active: true,
  },
  {
    title: "Thank you for a jointly successful 2022!",
    imageUrl: HolidaysNews,
    active: true,
  },
  {
    title: "Immotech with a BIG update! The new 3.0 version of the popular app gives even more to its users",
    imageUrl: immotechNews,
    active: true,
  },
  {
    title: "Amarant app with more than 10 thousand reviews on Google Play and best possible rating",
    imageUrl: amarantNews,
    active: true,
  },
  {
    title: "FIGHT SCOUT is now available",
    imageUrl: FSmin,
    active: true,
  },
];

function Footer({ setHeaderStatus, setSelectedArticle }) {
  const [activeSlide, setActiveSlide] = useState(0);

  const slider = useRef();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      setHeaderStatus(null);
    }
  }, [location.pathname, setHeaderStatus]);

  return (
    <div className="footer-container" id="footer">
      <div className="arrows-container">
        <div className="pseudo-element">News</div>
        <div className="arrows">
          <div
            className={`left-arrow ${activeSlide === 0 && "inactive"}`}
            id="left"
            onClick={() => {
              slider.current.slickPrev();
            }}
          />
          <div
            className={`right-arrow ${activeSlide + 3 === articles.length && "inactive"}`}
            id="right"
            onClick={() => {
              slider.current.slickNext();
            }}
          />
        </div>
      </div>
      <div className="news-container"></div>
      <Slider ref={slider} {...settings}>
        {articles.map((article, j) => {
          return (
            <div className="article" id={`article${j}`} key={j}>
              <div
                className="article-image"
                style={{
                  backgroundImage: `url(${article.imageUrl})`,
                  width: j === 5 && "23%",
                }}
                onClick={() => {
                  if (article.active) history.push(`article/${j}`);
                }}
              />
              <div className="article-info">
                <p
                  className="title"
                  style={{
                    fontSize: window.innerWidth > 900 && window.innerWidth < 1650 ? "16px" : "20px",
                  }}
                  onClick={() => {
                    if (article.active) history.push(`article/${j}/${NewsText[j].id}`);
                  }}
                >
                  {article.title}
                </p>
                {article.active ? (
                  <div
                    style={{
                      width: "101%",
                      display: "flex",
                      justifyContent: "flex-end",
                      height: "10%",
                      alignItems: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (article.active) history.push(`article/${j}/${NewsText[j].id}`);
                    }}
                  >
                    <div className="arrow-container">
                      <div className="active-arrow" />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setHeaderStatus: (payload) => dispatch(setHeaderStatus(payload)),
  setSelectedArticle: (payload) => dispatch(setSelectedArticle(payload)),
});

export default connect(null, mapDispatchToProps)(Footer);
