/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { setHeaderStatus, setCurrentStatus } from "../../actions";
import { history } from "../../config/stores";
// import Presentation from "../../files/Fidweb-Presentation.pdf";
import Presentation from "../../files/FidWeb-Company-Presentation-2023.pdf";
import "./styles.scss";

function Header({ setHeaderStatus, currentStatus, setCurrentStatus }) {
  return (
    <div className="header-container">
      <div
        className="fidweb-logo"
        onClick={() => {
          setHeaderStatus("Software Development");
          history.push("/");
          setCurrentStatus("home");
        }}
      />
      <div className="buttons-container">
        <b
          style={{ cursor: "pointer" }}
          onClick={() => window.open(Presentation)}
        >
          Our portfolio
        </b>
        <b
          className={`${currentStatus === "form" && "opened"}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (currentStatus === "form") {
              setCurrentStatus("home");
              history.push("/");

            }
            else {
              setCurrentStatus("form");
              history.push("/");

            }
          }}
        >
          {" "}
          Contact us
        </b>
      </div>
      <div className="languages-container"></div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  headerStatus: state.stateControl.headerStatus,
  currentStatus: state.stateControl.currentStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderStatus: (payload) => dispatch(setHeaderStatus(payload)),
  setCurrentStatus: (payload) => dispatch(setCurrentStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
