/* eslint-disable */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { history } from "../../config/stores";
import { setHeaderStatus } from "../../actions";
import News from "../../config/NewsText";
import "./styles.scss";

const Article = ({ setHeaderStatus }) => {
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const articleID = window.location.href.split("/")[4];

  useEffect(() => {
    if (window.innerWidth > 900) {
      document.getElementById("footer").classList.add("disable");
      document.getElementById("footer").style.display = "none";
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {isMobileVersion === 1 ? (
        <>
          <div className="mobile-article-container">
            <div
              className="mobile-arrow-left"
              onClick={() => {
                history.push("/news");
                setHeaderStatus("Software Development");
                document.getElementById("footer").classList.remove("disable");
              }}
            />
            <div className="mobile-neshto" style={{ paddingBottom: "20px" }}>
              {News[articleID].logo && (
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${News[articleID].logo})`,
                  }}
                />
              )}
              <div
                className="mobile-right-article-container"
                style={{
                  backgroundImage: `url(${News[articleID].image})`,
                  height: !News[articleID].logo && "35vh",
                }}
              />
              <h3 style={{ fontSize: "20px", marginLeft: "6%", marginTop: "2%" }}>{News[articleID].title}</h3>

              {News[articleID].parts.map((part) => {
                return News[articleID].html ? <div className={`mobile-article-text ${part.includes("no-padding") ? "no-padding" : ""} ${part.includes("no-margin") ? "no-margin" : ""} ${part.includes("bulletpoint") ? "bulletpoint" : ""}`} dangerouslySetInnerHTML={{ __html: part }}></div> : <div className="mobile-article-text">{part}</div>;
              })}
              <div className={`icons-container ${News[articleID]?.images ? "images-logos" : ""}`}>
              {News[articleID].apple && <div className={`apple-icon ${News[articleID].title?.includes("Scoutify") && "scoutify"}`} onClick={() => window.open(News[articleID].apple)} />}
                {News[articleID].play && <div className={`play-icon ${News[articleID].title?.includes("Scoutify") && "scoutify"}`} onClick={() => window.open(News[articleID].play)} />}
                {News[articleID].site && <div className="apple-icon" onClick={() => window.open(News[articleID].site)} />}
                {News[articleID]?.images &&
                  News[articleID]?.images?.map((el, index) => {
                    return <div className={`logo-icon-${el.logo}`} onClick={() => window.open(el?.link)} />;
                  })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="article-container">
          <div className="left-article-container">
            <div
              className="arrow-left"
              onClick={() => {
                history.push("/");
                setHeaderStatus("Software Development");
                document.getElementById("footer").classList.remove("disable");
              }}
            />
            <div className="neshto">
              {News[articleID].logo && (
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${News[articleID].logo})`,
                  }}
                />
              )}

              <h2>{News[articleID].title}</h2>

              {News[articleID].parts.map((part, partIndex) => {
                return News[articleID].html ? (
                  <div className={`article-text ${part.includes("no-padding") ? "no-padding" : ""} ${part.includes("no-margin") ? "no-margin" : ""} ${part.includes("bulletpoint") ? "bulletpoint" : ""} ${part.includes("medium") ? "medium" : ""} ${part.includes("last") ? "last" : ""}`} dangerouslySetInnerHTML={{ __html: part }}></div>
                ) : News[articleID]?.video && partIndex === 2 ? (
                  <>
                    <div
                      className="video-image video"
                      onClick={() => {
                        window.open(News[articleID]?.video);
                      }}
                    />

                    <div className="article-text">{part}</div>
                  </>
                ) : (
                  <div className="article-text">{part}</div>
                );
              })}
              <div className={`icons-container ${News[articleID]?.images ? "images-logos" : ""}`}>
                {News[articleID].apple && <div className={`apple-icon ${News[articleID].title?.includes("Scoutify") && "scoutify"}`} onClick={() => window.open(News[articleID].apple)} />}
                {News[articleID].play && <div className={`play-icon ${News[articleID].title?.includes("Scoutify") && "scoutify"}`} onClick={() => window.open(News[articleID].play)} />}
                {News[articleID].site && <div className="apple-icon" onClick={() => window.open(News[articleID].site)} />}
                {News[articleID]?.images &&
                  News[articleID]?.images?.map((el, index) => {
                    return <div className={`logo-icon-${el.logo}`} onClick={() => window.open(el?.link)} />;
                  })}
              </div>
            </div>
          </div>
          <div
            className="right-article-container"
            style={{
              backgroundImage: `url(${News[articleID].image})`,
              backgroundSize: News[articleID].special ? "100%" : News[articleID].html && (window.innerWidth > 1200 ? "cover" : "contain"),
            }}
          ></div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  headerStatus: state.stateControl.headerStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderStatus: (payload) => dispatch(setHeaderStatus(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Article);
