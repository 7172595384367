/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form } from "../../components";
import { connect } from "react-redux";
import "./styles.scss";
import { setCurrentStatus } from "../../actions";
import projectData from "../../config/projectData";
import ProjectSlider from "../../components/ProjectSlider/ProjectSlider";

function Home({ currentStatus }) {
  const [show, setShow] = useState(0);
  const [selProject, setSelProject] = useState(projectData[0]);
  useEffect(() => {
    if (window.innerWidth > 900) {
      if (currentStatus === "home") {
        document.getElementById("footer").style.display = "block";
      } else document.getElementById("footer").style.display = "none";
    }
  }, [currentStatus]);

  useEffect(() => {
    if (window.innerWidth < 900) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [currentStatus]);

  return (
    <div className="home-container">
      {window.innerWidth < 900 ? (
        <div className="home-inner-container">
          {currentStatus !== "home" ? (
            <>
              <div className="left-container"></div>
              <div className="right-container">
                <Form />
              </div>
            </>
          ) : (
            <>
              <div className="left-container">
                <div className="top-container">
                  <h1>Making software people actually love</h1>
                </div>

                <div className="text-container">
                  <div className="red-dot" />
                  Building and upgrading mobile apps, web-based solutions and heavy enterprise programs
                </div>
                <div className="text-container">
                  <div className="red-dot" />
                  Talented software engineers from Eastern Europe and management team with a global focus
                </div>
                <div className="text-container">
                  <div className="red-dot" />
                  Serving to Millions of Users and growing
                </div>
                {show ? <Form /> : ""}
              </div>
              <div className="right-container">
                <ProjectSlider setSelProject={setSelProject} selProject={selProject} />
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={`home-inner-container ${currentStatus !== "home" && "form"}`}
          style={{
            width: window.innerWidth < 1550 && currentStatus !== "home" ? "94%" : null,
          }}
        >
          <div
            className={`left-container ${currentStatus !== "home" && "form"}`}
            style={{
              height: window.innerWidth < 1550 && currentStatus !== "home" ? "90%" : null,
            }}
          >
            {currentStatus !== "home" ? (
              <>
                <div className="contact-form-collage" />
              </>
            ) : (
              <>
                <div className="top-container">
                  <h1>Making software people actually love</h1>
                </div>
                <div className="text-container">
                  <div className="red-dot" />
                  Building and upgrading mobile apps, web-based solutions and heavy enterprise programs
                </div>
                <div className="text-container">
                  <div className="red-dot" />
                  Talented software engineers from Eastern Europe and management team with a global focus
                </div>
                <div className="text-container">
                  <div className="red-dot" />
                  Serving to Millions of Users and growing
                </div>
                <ProjectSlider setSelProject={setSelProject} selProject={selProject} />
              </>
            )}
          </div>
          <div
            className={`right-container ${currentStatus !== "home" && "form"}`}
            style={{
              height: window.innerWidth < 1550 && currentStatus !== "home" ? "90%" : null,
            }}
          >
            {currentStatus === "home" ? (
              <div className="selected-project-container">
                <div className="project-info">
                  <h2 className="project-text">{selProject.text}</h2>
                  <div className="icons-container">
                    {selProject.email && <div className="email-icon" />}
                    {selProject.apple && <div className="apple-icon" onClick={() => window.open(selProject.apple)} />}
                    {selProject.play && <div className="play-icon" onClick={() => window.open(selProject.play)} />}
                    {selProject.site && <div className="site-icon" onClick={() => window.open(selProject.site)} />}
                  </div>
                </div>
                <div
                  className="project-image"
                  style={{
                    backgroundImage: `url(${selProject.imageUrl})`,
                  }}
                />
              </div>
            ) : (
              <Form />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  headerStatus: state.stateControl.headerStatus,
  currentStatus: state.stateControl.currentStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentStatus: (payload) => dispatch(setCurrentStatus(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
