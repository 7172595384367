/* eslint-disable */
import React, { useState, useEffect } from "react";
import formCompleted from "../../LottieAnimations/FidWeb-Success-Animation.json";
import Lottie from "react-lottie";
import "./styles.scss";
import { connect } from "react-redux";
import { sendEmail } from "../../actions";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: formCompleted,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Form({ sendEmail }) {
  const [formSent, setFormSent] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    details: "",
  });

  function validateEmail() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(data.email) === false) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (window.innerWidth < 900) {
      document.getElementsByTagName("BODY")[0].style.overflowY = "scroll";
    }
  });

  return (
    <>
      {window.innerWidth < 900 ? (
        <div className="mobile-reverse">
          <div className="form-container" style={{ height: "unset" }}>
            {formSent ? (
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "16px" }}>
                Your inquiry has been successfully sent!
                </p>
                <Lottie
                  options={defaultOptions}
                  height={window.innerWidth < 900 ? "50%" : 280}
                  width={window.innerWidth < 900 ? "100%" : 400}
                />
                <p style={{ fontSize: "16px", marginBottom: "2%" }}>
                We will contact you soon!
                </p>
              </div>
            ) : (
              <>
                <div className="flex-container">
                  <div
                    className="top-container"
                    style={{ marginLeft: "-0.5%" }}
                  >
                    <h1>Contact us</h1>
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={`Name*`}
                      value={data.name}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["name"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={`Email*`}
                      value={data.email}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["email"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={`Phone`}
                      value={data.phone}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["phone"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={`Company`}
                      value={data.company}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["company"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="textarea-wrapper">
                  <textarea
                    className="textarea"
                    placeholder={`More details*`}
                    value={data.details}
                    onChange={(event) => {
                      setData({
                        ...data,
                        ["details"]: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex-container flex-end">
                  <div
                    className={`button-container ${
                      data.name.length < 1 && data.email.length < 1
                        ? "inactive"
                        : null
                    }`}
                    id="link"
                    onClick={() => {
                      if (validateEmail()) {
                        sendEmail({
                          system: "fidweb",
                          sender: data.name,
                          company: data.company,
                          email: data.email,
                          phoneNumber: data.phone,
                          message: data.details,
                          onSuccess: () => {
                            setData({
                              name: "",
                              email: "",
                              phone: "",
                              company: "",
                              details: "",
                            });
                          },
                        });
                        setFormSent(true);
                      } else
                        Swal.fire(
                          "Въведеният емайл не е правилен.",
                          "",
                          "error"
                        );
                    }}
                  >
                    Send
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="form-container">
            {formSent ? (
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "20px" }}>
                  Your inquiry has been successfully sent!
                </p>
                <Lottie
                  options={defaultOptions}
                  height={window.innerWidth < 900 ? "50%" : 250}
                  width={window.innerWidth < 900 ? "100%" : 400}
                />
                <p style={{ fontSize: "20px", marginBottom: "2%" }}>
                  We will contact you soon!
                </p>
              </div>
            ) : (
              <>
                <div className="top-container" style={{ marginLeft: "-0.5%" }}>
                  <h1>Contact us</h1>
                </div>
                <div className="flex-container">
                  <div className="input-wrapper">
                    <input
                      type="text"
                      value={data.name}
                      placeholder={`Name*`}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["name"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={`Email*`}
                      value={data.email}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["email"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex-container">
                  <div className="input-wrapper">
                    <input
                      type="text"
                      value={data.phone}
                      placeholder={`Phone`}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["phone"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      placeholder={`Company`}
                      value={data.company}
                      onChange={(event) => {
                        setData({
                          ...data,
                          ["company"]: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="textarea-wrapper">
                  <textarea
                    className="textarea"
                    placeholder={`More details*`}
                    value={data.details}
                    onChange={(event) => {
                      setData({
                        ...data,
                        ["details"]: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex-container flex-end">
                  <div
                    className={`button-container ${
                      data.name.length < 1 && data.email.length < 1
                        ? "inactive"
                        : null
                    }`}
                    id="link"
                    onClick={() => {
                      if (validateEmail()) {
                        sendEmail({
                          system: "fidweb",
                          sender: data.name,
                          company: data.company,
                          email: data.email,
                          phoneNumber: data.phone,
                          message: data.details,
                          onSuccess: () => {
                            setData({
                              name: "",
                              email: "",
                              phone: "",
                              company: "",
                              details: "",
                            });
                          },
                        });
                        setFormSent(true);
                      } else
                        Swal.fire(
                          "Въведеният емайл не е правилен.",
                          "",
                          "error"
                        );
                    }}
                  >
                    Send
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="info-container">
            <div className="top-container" style={{ marginLeft: "-0.5%" }}>
              <h1>Our contacts</h1>
            </div>
            <div className="inner-flex-container">
              <div className="location-logo" />
              <p className="address">
                Ivan Abadjiev Street, 2 office building 4th floor Sofia,
                Bulgaria
              </p>
            </div>
            <div className="inner-flex-container">
              <div className="mail-logo" />
              <p className="email">team@fidweb.net</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});

export default connect(null, mapDispatchToProps)(Form);
