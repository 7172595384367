/* eslint-disable */
import React, { useEffect } from "react";
import { history } from "../../config/stores";
import "./styles.scss";
import fightScout from "../../assets/mobile/fightscout.png";
// import wink from "../../assets/mobile/wink.png";
import wink from "../../assets/fidweb-wink-updates_optimized.png";
import amarantNews from "../../assets/amarant-mobile.png";
import immotechNews from "../../assets/mobile/immotech-news-mobile.png";
import HolidaysNews from "../../assets/mobile/2022-mobile-2.png";
import NewsText from "../../config/NewsText";
import fightScoutNew from "../../assets/mobile/fightcout-news-mobile.png";
import gfl from "../../assets/mobile/gfl-news-mobile.png";
import proakt from "../../assets/mobile/proakt-news-mobile.png";
import fightScoutChange from "../../assets/mobile/fightscout-news-mobile-new.png";
import fightScoutBKFC from "../../assets/fightscout_bkfc.png";
import FSmin from "../../assets/1-min.png";
import HolidayPicture from "../../assets/mobile/3-march-news-mobile.png";
import fantastico from "../../assets/fidweb-fantastico_optimized.png";
import fightScoutCommunication from "../../assets/fidweb-article-fight-scout-updates_optimized.png";
import fightScoutCombine from "../../assets/fightscout-web-new.png";
import fightScoutWebsite from "../../assets/fidweb-article-fight-scout-new-website.png";
import testMyStress from "../../assets/fidweb-practicle-stress_optimized.png";
import testMyStressNew from "../../assets/fidweb-testmystress-coming-soon.png";
import mobiWash from "../../assets/fidweb-mobiwash_optimized.png";
import immotechGermany from "../../assets/fidweb-immotech-germany_optimized.png";
import enrpi from "../../assets/fidweb-enrpi-new-client_optimized.png";
import winkNews from "../../assets/fidweb-wink-articles_optimized.png";
import alpi from "../../assets/fidweb-alpi-article_optimized.png";
import amarant3 from "../../assets/amarant-3.png";
import scoutify from "../../assets/Scoutify-App-Article-Image.png";

const articles = [
  {
    title: "Scoutify App: Revolutionising Athlete Talent Tracking",
    imageUrl: scoutify,
    active: true,
  },
  {
    title: "Amarant App Achieves Stellar Rating on App Store and Google Play Store!",
    imageUrl: amarant3,
    active: true,
  },
  {
    title: "Fight Scout Named Official App for Fighter Recruitment for BKFC",
    imageUrl: fightScoutBKFC,
    active: true,
  },
  {
    title: "FIGHT SCOUT presents its new website",
    imageUrl: fightScoutWebsite,
    active: true,
  },
  {
    title: "Unveiling the Partnership with the Fantastico group: Empowering Growth and Knowledge",
    imageUrl: fantastico,
    active: true,
  },
  {
    title: "Elevating Communication in the Digital Age: The All-New FightScout Mobile App",
    imageUrl: fightScoutCommunication,
    active: true,
  },
  {
    title: "Wink Mobile App’s Game-Changing Payment Update",
    imageUrl: wink,
    active: true,
  },
  {
    title: "Introducing Practical Stress: Evolutionizing Psychological Consultations and Stress Measurement Cutting-Edge Platform",
    imageUrl: testMyStress,
    active: true,
  },
  {
    title: "MobiWash: Revolutionizing Car Washing with Convenience at Your Fingertips",
    imageUrl: mobiWash,
    active: true,
  },
  {
    title: "Immotech App’s Major Partnership Set to Redefine the German Tech Scene",
    imageUrl: immotechGermany,
    active: true,
  },
  {
    title: "Empowering Efficiency: EnRPI Welcomes V&K Energy as New Client",
    imageUrl: enrpi,
    active: true,
  },
  {
    title: "Wink Mobile App: Earning Recognition and Making Waves in Forbes and Kapital",
    imageUrl: winkNews,
    active: true,
  },
  {
    title: "ALPI's Remarkable Journey: Featured in Forbes Magazine",
    imageUrl: alpi,
    active: true,
  },
  {
    title: "New project Test My Stress",
    imageUrl: testMyStressNew,
    active: true,
  },
  {
    title: "Immotech launching soon in Germany",
    imageUrl: immotechGermany,
    active: true,
  },
  {
    title: "Честит Национален празник, българи!",
    imageUrl: HolidayPicture,
    active: true,
  },
  {
    title: "New Partnership Announced with Social Media Platform FightScout",
    imageUrl: fightScoutCombine,
    active: true,
  },
  {
    title: "EnRPi welcomes Proakt",
    imageUrl: proakt,
    active: true,
  },
  {
    title: "New Year, new amazing project completed!",
    imageUrl: gfl,
    active: true,
  },
  {
    title: "Fight Scout raises 50 000$ pre-seed funding!",
    imageUrl: fightScoutNew,
    active: true,
  },
  {
    title: "Thank you for a jointly successful 2022!",
    imageUrl: HolidaysNews,
    active: true,
  },
  {
    title: "Immotech with a BIG update! The new 3.0 version of the popular app gives even more to its users",
    imageUrl: immotechNews,
    active: true,
  },
  {
    title: "Amarant app with more than 10 thousand reviews on Google Play and best possible rating",
    imageUrl: amarantNews,
    active: true,
  },
  {
    title: "FIGHT SCOUT is now available",
    imageUrl: FSmin,
    active: true,
  },
  // {
  //   title: "The future of transport is coming with FidWeb",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/3.png",
  // },
  // {
  //   title: "New module for business users",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/4.png",
  // },
  // {
  //   title: "Alpi nature online store",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/5.png",
  // },
  // {
  //   title: "Amarant - a leading insurance broker",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/6.png",
  // },
  // {
  //   title: "LinkedIn for the world of combat sports",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/7.png",
  // },
  // {
  //   title: "A digital system that makes every driver better",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/8.png",
  // },
  // {
  //   title: "System for licensed electricity suppliers",
  //   imageUrl: "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/9.png",
  // },
  // {
  //   title: "Everything for your property management",
  //   imageUrl:
  //     "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/10.png",
  // },
  // {
  //   title: "In the absence of affordable medical care around the world",
  //   imageUrl:
  //     "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/11.png",
  // },
  // {
  //   title: "SOCOURT - for every moment",
  //   imageUrl:
  //     "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/12.png",
  // },
  // {
  //   title: "SOCOURT - for tournaments and competitions",
  //   imageUrl:
  //     "https://immotech-web.s3.eu-central-1.amazonaws.com/FidWeb/13.png",
  // },
];

const MobileNews = () => {
  useEffect(() => {
    document.getElementsByTagName("BODY")[0].style.overflowY = "scroll";
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="mobile-blog" id="footer">
      <p className="blog-header">News</p>
      <div className="blog-container">
        {articles.map((article, j) => {
          return (
            <div className="blog">
              <div
                className="blog-image"
                style={{
                  backgroundImage: `url(${article.imageUrl})`,
                  backgroundPosition: j < 3 && "unset",
                }}
                onClick={() => {
                  if (article.active) history.push(`article/${j}/${NewsText[j].id}`);
                }}
              />
              <p
                className="blog-title"
                // style={{ paddingTop: j === 3 || j === 4 ? "25px" : null }}
                style={{ paddingTop: "25px 10px 0 10px" }}
                onClick={() => {
                  if (article.active) history.push(`article/${j}/${NewsText[j].id}`);
                }}
              >
                {article.title}
              </p>
              {article.active ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "12%",
                    alignItems: "flex-end",
                    cursor: "pointer",
                    paddingTop: "8px",
                    marginTop: "auto",
                  }}
                  onClick={() => {
                    if (article.active) history.push(`article/${j}/${NewsText[j]?.id}`);
                  }}
                >
                  <div className="arrow-container">
                    <div className="active-arrow" />
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default MobileNews;
