
/* eslint-disable */
import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { Header, Footer, MobileHeader } from "../../components";
import { Home, Blog, Article, MobileNews } from "../../screens";
import { MobileBlog } from "../MobileBlog";

class Routes extends Component {
  render() {
    return (
      <div className="dashboard-wrap">
        {window.innerWidth < 900 ? <MobileHeader /> : <Header />}
        <Switch>
          <Route exact path="/" component={Home} />
          {window.innerWidth < 900 && (
            <Route exact path="/news" component={MobileNews} />
          )}
          <Route path="/article" component={Article} />
        </Switch>
        {window.innerWidth < 900 ? <MobileBlog /> : <Footer />}
      </div>
    );
  }
}

export default withRouter(Routes);
