/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { switchMap, catchError } from "rxjs/operators";
import { StateTypes } from "../actions/stateControl";
import { basicAuth } from "../config/Headers";

export const sendEmailEpic = (action$) => {
  return action$.pipe(
    ofType(StateTypes.SEND_EMAIL),
    switchMap(({ payload }) => {
      return ajax({
        url: `https://web-emails-dev.herokuapp.com/email`,
        method: "POST",
        headers: basicAuth,
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err)
          });
        })
      );
    })
  );
};
